.home-section {
  padding: 80px 0;
}
.scrollable {
  position: relative;
  width: 100%;
  height: 100vh;
}
.section-intro {
  padding: 40px 0 60px;
  background: #ffffff;
  h1 {
    font-size: 32px;
    margin: 2rem 0 1rem;
    font-weight: 300;
  }
  p {
    font-size: 16px;
    max-width: 450px;
    margin: 0 auto 1.4rem;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
.section-demos {
  position: relative;
  background: #ffffff;
  .demo-box {
    h3 {
      margin: 0 0 1.4rem;
      font-size: 28px;
      font-weight: 300;
    }
    img {
      width: 100%;
      height: auto;
      float: left;
    }
    .screenshot {
      overflow: hidden;
      border-radius: 8px;
      margin-bottom: 2rem;
      min-height: 180px;
      cursor: pointer;
    }
  }
}

