// General
$transitionDuration: 300ms !default;
$gutter: 1rem !default;
$transitionTiming: cubic-bezier(0.35, 0, 0.25, 1);
$sidebarTrnTime: 0.3s;

// Widths
$sidebar-width: 15rem !default;
$compact-sidebar-width: 48px !default;
$compact-big-sidebar-width: 150px !default;

// Typography
$font-family-base: "Bliss Pro",sans-serif !default ;//"Titillium Web", sans-serif; // Roboto, "Helvetica Neue", sans-serif !default;
$font-size-base: 0.775rem !default;
$font-size-menu: 0.875rem !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$headings-margin-bottom: (1rem / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

$color-psblue: #2b0d61;
