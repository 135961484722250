$black-87-opacity: rgba(0, 0, 0, 0.87);

// You can define colors here (using http://mcg.mbitson.com)
$mat-primary: (
	50: #e6e2ec,
	100: #bfb6d0,
	200: #9586b0,
	300: #6b5690,
	400: #4b3179,
	500: #2b0d61,
	600: #260b59,
	700: #20094f,
	800: #1a0745,
	900: #100333,
	A100: #886bff,
	A200: #5f38ff,
	A400: #3605ff,
	A700: #2e00eb,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff
	)
);
$mat-accent: (
	50: #fdeee0,
	100: #fad4b3,
	200: #f6b780,
	300: #f29a4d,
	400: #f08426,
	500: #ed6e00,
	600: #eb6600,
	700: #e85b00,
	800: #e55100,
	900: #e03f00,
	A100: #ffffff,
	A200: #ffded4,
	A400: #ffb5a1,
	A700: #ffa188,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff
	)
);
// mandatory stuff for theming
$egret-primary: mat-palette($mat-primary);
$egret-accent: mat-palette($mat-accent);
// include the custom theme components into a theme object
$egret-theme: mat-light-theme($egret-primary, $egret-accent);
$primary-dark: darken(map-get($mat-primary, 500), 8%);
// include the custom theme object into the angular material theme
.egret-blue {
	@include angular-material-theme($egret-theme);
	.mat-bg-primary,
	.topbar,
	.chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
	.ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
	.fileupload-drop-zone.dz-file-over,
	.toolbar-avatar.online > .status-dot,
	.cal-open-day-events,
	div.hopscotch-bubble {
		background: map-get($mat-primary, 500) !important;
		color: #ffffff !important;
	}
	.mat-color-primary,
	.list-item-active,
	.sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
	.sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
	.sidenav-hold .sidenav li.open > a > span {
		color: map-get($mat-primary, 500) !important;
	}
	.topnav ul.menu > li > div.open > a,
	.topnav ul.menu > li > div.open > div,
	.sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
	.sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
	.list-item-active {
		border-color: map-get($mat-primary, 500) !important;
	}
	.mat-icon {
		cursor: pointer !important;
	}
	.sidebar-panel {
		background: url("../../../images/sidebar-bg.jpg") no-repeat;
	}
	.sidebar-compact ul.submenu,
	.default-bg {
		background: #ffffff !important;
	}
	/* Hopscotch Tour */
	div.hopscotch-bubble {
		border-color: $primary-dark;
	}
	/* up arrow */
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
		border-bottom: 17px solid $primary-dark;
	}
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
		border-bottom: 17px solid map-get($mat-primary, 500);
		top: -16px;
	}
	/* right arrow */
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
		border-left: 17px solid $primary-dark;
	}
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
		border-left: 17px solid map-get($mat-primary, 500);
		left: -1px;
	}
	/* bottom arrow */
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
		border-top: 17px solid $primary-dark;
	}
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
		border-top: 17px solid map-get($mat-primary, 500);
		top: -18px;
	}
	/* Left Arrow */
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
		border-right: 17px solid $primary-dark;
	}
	div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
		left: 1px;
		border-right: 17px solid map-get($mat-primary, 500);
	}
	.mat-bg-accent,
	div.hopscotch-bubble .hopscotch-bubble-number,
	div.hopscotch-bubble .hopscotch-nav-button.next:hover,
	div.hopscotch-bubble .hopscotch-nav-button.next {
		background-color: map-get($mat-accent, 500);
		color: black;
	}
	.mat-bg-warn {
		background-color: #f44336;
		color: white;
	}
	.mat-color-accent {
		color: map-get($mat-accent, 500);
	}
	.mat-color-warn {
		color: #f44336;
	}
	.mat-color-default {
		color: rgba(0, 0, 0, 0.87);
	}
	.handsontable {
		font-size: 11px;
	}
	.handsontable table thead th {
		white-space: pre-line;
		vertical-align: middle;
		font-weight: bold;
	}
	.cdk-overlay-dark-backdrop {
		background: rgba(0, 0, 0, 0.85);
	}
}
